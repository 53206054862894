/* src/styles.css */

@font-face {
    font-family: 'Vastago';
    src: url('../src/assets/fonts/VastagoGrotesk-Bold.otf') format('truetype'); /* Update the path */
    /* You can include more font formats like woff and woff2 if needed */
    font-weight: normal;
    font-style: normal;
  }
  
  /* Optional: Apply the font to specific elements */
  /* body {
    font-family: 'Vastago', sans-serif;
  }   */