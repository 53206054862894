@tailwind base;
@tailwind components;
@tailwind utilities;

.hero-image {
  background-image: url("../src//assets//images/image.png");
}

::placeholder {
  font-weight: 400;
  font-size: 16px;
  color: #666666;
  font-family: "Inter", "sans-serif";
}

body {
  overflow-x: hidden;
}
